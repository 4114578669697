//@import '~antd/dist/antd.less';



.PhoneInput {
  line-height: 0.5 !important;
}

.PhoneInput input {
  height: 32px;
  font-size: 14px;
  padding-left: 8px;
}

.ReactModalPortal {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  z-index: 10000 !important;
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  z-index: 10000 !important;
  opacity: 0;
  transform: translateY(-100px);
}
.ReactModal__Body--open {
  height: 100% !important;
  overflow: hidden !important;
}

.react-tel-input .form-control {
  width: inherit !important;
  padding-left: 50px !important;
}

@media (max-width: 600px) {
  .react-tel-input .form-control {
    height: 35px !important;
    line-height: 35px !important;
    font-size: 16px !important;
  }
}
